
(function(){
  const TIMEOUT_POPUP_SHOW = 400;
  const TIMEOUT_POPUP_RESPOND = 5000;
  const TIMEOUT_CLOSE_WINDOW_LONG = 5000;
  const TIMEOUT_CLOSE_WINDOW_SHORT = 10;

  const CALL_INDEX_1 = 1;
  const CALL_INDEX_2 = 2;

  const MATCH_1 = 1;
  const MATCH_2 = 2;
  const MATCH_LENGTH = 3;

  var popupElement;
  var iframeElement;
  var closeTimeout = TIMEOUT_CLOSE_WINDOW_SHORT;

  var makePopupShowLoadingAnimation = () => {
    var body = iframeElement.contentWindow.document.body;
    body.innerHTML = "<style>svg{width:150px}</style><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'><radialGradient id='a12' cx='0.55' fx='0.6' cy='.3125' fy='.5125' gradientTransform='scale(1.7)'><stop offset='0' stop-color='#026C45'></stop><stop offset='.25' stop-color='#026C45' stop-opacity='1'></stop><stop offset='.6' stop-color='#026C45' stop-opacity='.6'></stop><stop offset='.8' stop-color='#026C45' stop-opacity='.3'></stop><stop offset='1' stop-color='#026C45' stop-opacity='0'></stop></radialGradient><circle transform-origin='center' fill='none' stroke='url(#a12)' stroke-width='35' stroke-linecap='round' stroke-dasharray='200 1000' stroke-dashoffset='0' cx='100' cy='100' r='70'><animateTransform type='rotate' attributeName='transform' calcMode='spline' dur='1' values='0;360' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite'></animateTransform></circle><circle transform-origin='center' fill='none' stroke='#026C45' stroke-width='35' stroke-linecap='round' stroke-dasharray='250 1000' stroke-dashoffset='-250' cx='100' cy='100' r='70'><animateTransform type='rotate' attributeName='transform' calcMode='spline' dur='1' values='0;360' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite'></animateTransform></circle><circle transform-origin='center' fill='none' opacity='.2' stroke='#026C45' stroke-width='35' stroke-linecap='round' cx='100' cy='100' r='70'></circle></svg>";
    body.style = "margin:0; background:#ffffff; height: 100%;display:flex;justify-content: center; align-items: center;";
  };

  var removePopup = () => {
    document.querySelectorAll(".brite-popup").forEach( (oldPopup) => {
      oldPopup.parentNode.removeChild(oldPopup);
    });
  };

  var createPopup = () => {
    closeTimeout = TIMEOUT_CLOSE_WINDOW_SHORT;
    removePopup();
    popupElement = document.createElement("div");
    popupElement.classList = "brite-popup";
    popupElement.innerHTML = "<div><iframe style='width:400px;height:600px;' src='about:blank' id='iframe_" + Date.now() + "'></iframe></div>";
    popupElement.onclick = removePopup;
    document.body.prepend(popupElement);

    iframeElement = popupElement.querySelector("iframe");
    setTimeout(makePopupShowLoadingAnimation, TIMEOUT_POPUP_SHOW);
  };


  var styleButton = (button) => {
    if(button) {
      button.classList.add("active");
      setTimeout(() => {
        button.classList.remove("active");
      }, TIMEOUT_POPUP_RESPOND);
    }
  };

  var doRestCallLookup1 = (callback) => {
    fetch("/wp-json/spring-brite/v1/lookup-1/")
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          removePopup();
        }
      })
      .then((data) => {
        if(data && data.url && data.id) {
          iframeElement.src = data.url;
          callback(data);
        }
      })
      .catch(() => {
        removePopup();
      });
  };

  var doRestCallLookup2 = (id, callback) => {
    fetch("/wp-json/spring-brite/v1/lookup-2/?id="+ encodeURIComponent(id))
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          removePopup();
        }
      })
      .then((data) => {
        if(data && data.customer_id) {
          callback(data);
        }
      })
      .catch(() => {
        removePopup();
      });
  };

  var doRestCallLookup3 = (customerId, callback) => {
    fetch("/wp-json/spring-brite/v1/address-1/?customer_id="+ encodeURIComponent(customerId))
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          removePopup();
        }
      })
      .then((data) => {
        if(data) {
          callback(data);
        }
      })
      .catch(() => {
        removePopup();
      });
  };

  var setupBriteClass = (token, callback1) => {
    var currentCallNumber = CALL_INDEX_1;
    /*global Brite*/
    var brite = new Brite(token);
    brite.start({selector: "#" + iframeElement.id},
      (state) => {
        if (state === Brite.STATE_COMPLETED) {
          closeTimeout = TIMEOUT_CLOSE_WINDOW_LONG;
          if(currentCallNumber === CALL_INDEX_1) {
            currentCallNumber = CALL_INDEX_2;
            callback1();
          }
        }
        if(state === Brite.STATE_FAILED || state === Brite.STATE_CANCELLED) {
          removePopup();
        }
      },
      () => {
        setTimeout(() => {
          removePopup();
        }, closeTimeout);
        brite.stop();
      });
  };

  var assembleResult = (bankData, addressData) => {

    var address = {};
    if(addressData.address) {
      address = {
        streetAddress: addressData.address.street_address,
        zipCode: addressData.address.postal_code,
        city: addressData.address.city,
        country: addressData.address.country,
      };
    }
    var bank = {};
    if(bankData.bank && bankData.bank_account) {
      bank = {
        bankName: bankData.bank.name,
        bankHolder: bankData.bank_account.holder,
        iban: bankData.bank_account.iban,
        bban: bankData.bank_account.bban,
        swedishClearingNumber: '',
        swedishAccountNumber: ''
      };
      var match = /^(8[0-9]{4}|[^8][0-9]{3})(.{4,30})$/.exec(bankData.bank_account.bban);
      if(match && match.length === MATCH_LENGTH) {
        bank.swedishClearingNumber = match[MATCH_1];
        bank.swedishAccountNumber = match[MATCH_2];
      }
    }
    return {
      firstName: addressData.firstname,
      lastName: addressData.lastname,
      dob: addressData.dob,
      ssn: addressData.ssn,
      ...address,
      ...bank,
    };
  };

  window.brite = {
    popup: {
      show: function(button, callback) {
        styleButton(button);
        createPopup();
        doRestCallLookup1((data) => {
          // <-- first call to our backend done, user has not selected account
          setupBriteClass(data.token, () => {
            // <-- user has finished selecting account, trying to close the popup

            doRestCallLookup2(data.id, (bankData) => {
              // <-- bankData contains bank and account
              doRestCallLookup3(bankData.customer_id, (addressData) => {
                // <-- addressData contains address and ssn
                removePopup();
                setTimeout(() => {
                  if(callback) {
                    var result = assembleResult(bankData, addressData);

                    callback(result);
                  }
                }, TIMEOUT_POPUP_SHOW);
              });
            });
          });
        });
      }
    }
  };


})();